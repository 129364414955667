
// Animate sticky navigation
$( ".top-navigation" ).on( "sticky.zf.stuckto:top", function() {
    Foundation.Motion.animateIn(this, 'slide-in-down');
});

// Handle mobile Navigation
$( "[data-responsive-toggle]" ).on( "toggled.zf.responsiveToggle", function() {
    var $hamburger = $('.hamburger'),
        $topNavigation = $('.top-navigation'),
        $body = $('body');

    if($topNavigation.hasClass('is-stuck') && !$hamburger.hasClass('is-active') && !$body.hasClass('disable-scroll')){
        $body.addClass('disable-scroll');
    }else{
        $body.removeClass('disable-scroll');
    }
    $hamburger.toggleClass("is-active");
    $topNavigation.toggleClass('is-open')

});