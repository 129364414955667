// handle Preloader
$(window).ready(function(){
    $('.preloader').delay(1500).fadeOut();
});

// ScrollTop if Audioplayer is openend
/*$('.top-navigation .play-btn').click(function(){
    if($("#salue-radio").hasClass('open')){
        $("#salue-radio").removeClass('open');
    }else{
        $("html, body").animate({ scrollTop: 0 }, 600);
        $("#salue-radio").addClass('open');
    }
});*/

// Button ripple Effect
$(document).on('click', 'button', function(e) {
    var $rippleElement = $('<span class="ripple" />'),
        $buttonElement = $(this),
        btnOffset = $buttonElement.offset(),
        xPos = e.pageX - btnOffset.left,
        yPos = e.pageY - btnOffset.top,
        size = parseInt(Math.min($buttonElement.height(), $buttonElement.width()) * 0.5),
        animateSize = parseInt(Math.max($buttonElement.width(), $buttonElement.height()) * Math.PI);

    $rippleElement
        .css({
            top: yPos,
            left: xPos,
            width: size,
            height: size,

            backgroundColor: $buttonElement.data("ripple-color")
        })
        .appendTo($buttonElement)
        .animate({
            width: animateSize,
            height: animateSize,
            opacity: 0
        }, 500, function() {
            $(this).remove();
        });
});

// Album cover rotation
var angle = 360;

$('.album-cover img').click(function() {
    //$(this).toggleClass('active');
    $(this).css ({
        '-webkit-transform': 'rotate(' + angle + 'deg)',
        '-moz-transform': 'rotate(' + angle + 'deg)',
        '-o-transform': 'rotate(' + angle + 'deg)',
        '-ms-transform': 'rotate(' + angle + 'deg)'
    });
    angle+=360;
});


// share on facebook
function fbshare(){
    var sharer = "https://www.facebook.com/sharer/sharer.php?u=";
    window.open(sharer + location.href,'sharer', 'width=626,height=436');
}
$('.facebook.button').click(function() {
    event.preventDefault();
    event.stopPropagation();
    fbshare()
});

// Init Calendar
$(window).load( function() {
    $('#events-clndr').monthly({
        eventList: false
    });
});

$(document).ready(function(){
    // Init Slick Sliders
    $('.salue-programm-slider').slick({
        slidesToShow: 4,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    centerPadding: '40px',
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    centerPadding: '40px',
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    centerPadding: '40px',
                    slidesToShow: 1
                }
            }
        ]
    });
    
    $('.slider-cmp').slick({
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
        autoplaySpeed: 5000,
    });

    // init lightbox - lightcase
    $('a[data-rel^=lightbox]').lightcase({
        transition: 'scrollHorizontal',
        showSequenceInfo: false,
        showTitle: false
    });
});